export default {
    "title": "🇹🇭 ไทย",

    "很棒的住宿体验": "ประสบการณ์การเข้าพักที่ยอดเยี่ยม",
    "清爽干净，维护良好": "สดชื่นและสะอาด ดูแลรักษาได้ดี",
    "很棒的酒店和员工": "โรงแรมและพนักงานที่ยอดเยี่ยม",
    "价值符合预期": "มีค่าตรงตามที่คาดหวัง",
    "卓越的": "ยอดเยี่ยม",
    "美丽的土地，周末度假的好去处。 有趣的地方": "ที่ดินสวย เหมาะแก่การพักผ่อนช่วงสุดสัปดาห์ สถานที่ที่น่าสนใจ",

    "首页": "หน้าหลัก",
    "预订": "จอง",
    "关于": "เกี่ยวกับ",
    "发现": "ค้นพบ",
    "会员": "สมาชิก",
    "推荐计划": "แผนแนะนำ",
    "个人": "โปรไฟล์",
    "我的": "ของฉัน",
    "推荐": "แนะนำ",
    "事件": "เหตุการณ์",
    "登出": "ออกจากระบบ",
    "登录": "เข้าสู่ระบบ",
    "注册": "ลงทะเบียน",
    "语言": "ภาษา",
    "忘记密码": "ลืมรหัสผ่าน",
    "取消": "ยกเลิก",
    "确认": "ยืนยัน",
    "酒店": "โรงแรม",
    "银行卡列表": "รายการบัตรเครดิต",
    "银行列表": "รายชื่อธนาคาร",
    "持卡人姓名": "ชื่อผู้ถือบัตร",
    "银行卡号": "หมายเลขบัตรเครดิต",
    "支付密码": "รหัสผ่านการชำระเงิน",
    "提交": "ส่ง",
    "Pix列表": "รายการ Pix",
    "Pix类型": "ประเภท Pix",
    "Pix账号": "หมายเลขบัญชี Pix",
    "账号列表": "รายการบัญชี",
    "网络": "เครือข่าย",
    "地址": "ที่อยู่",
    "备注": "หมายเหตุ",
    "充值金额": "จำนวนเงินเติมเงิน",
    "支付类型": "ประเภทการชำระเงิน",
    "支付银行": "ธนาคารที่รับชำระเงิน",
    "USDT地址": "ที่อยู่ USDT",
    "二维码": "รหัสคิวอาร์",
    "充值渠道": "ช่องทางการเติมเงิน",
    "充值凭证": "หลักฐานการเติมเงิน",
    "立即登录": "เข้าสู่ระบบทันที",
    "用户名": "ชื่อผู้ใช้",
    "密码": "รหัสผ่าน",
    "邀请码": "รหัสเชิญ",
    "确认密码": "ยืนยันรหัสผ่าน",
    "手机": "โทรศัพท์มือถือ",
    "金额": "จำนวนเงิน",
    "新增": "เพิ่ม",
    "钱包余额": "ยอดเงินในบัญชี",
    "单价": "ราคาต่อหน่วย",
    "数量": "จำนวน",
    "总额": "รวมทั้งหมด",
    "奖励": "รางวัล",
    "手机号码": "หมายเลขโทรศัพท์มือถือ",
    "修改密码": "เปลี่ยนรหัสผ่าน",
    "登录密码": "รหัสผ่านเข้าสู่ระบบ",
    "当前密码": "รหัสผ่านปัจจุบัน",
    "新密码": "รหัสผ่านใหม่",
    "确认新密码": "ยืนยันรหัสผ่านใหม่",
    "保存修改": "บันทึกการเปลี่ยนแปลง",
    "充值": "เติมเงิน",
    "复制": "คัดลอก",
    "分享链接": "แชร์ลิงก์",
    "升级": "อัพเกรด",
    "登录时间": "เวลาเข้าสู่ระบบ",
    "信誉分": "ความน่าเชื่อถือ",
    "提现": "ถอนเงิน",
    "提现账号": "บัญชีถอนเงิน",
    "你确定要删除此账号吗": "คุณแน่ใจหรือไม่ว่าต้องการลบบัญชีนี้",
    "你确定要删除此地址吗": "คุณแน่ใจหรือไม่ว่าต้องการลบที่อยู่นี้",
    "提示": "เคล็ดลับ",
    "是": "ใช่",
    "否": "ไม่ใช่",
    "全部": "ทั้งหมด",
    "待处理": "รอดำเนินการ",
    "冻结中": "กำลังถูกล็อค",
    "已完成": "เสร็จสิ้นแล้ว",
    "我们的伙伴": "พาร์ทเนอร์ของเรา",
    "关于我们": "เกี่ยวกับเรา",
    "创建时间": "เวลาสร้าง",
    "你确定要登出当前账号吗？": "คุณแน่ใจหรือไม่ว่าต้องการออกจากบัญชีปัจจุบัน",
    "正在寻找酒店": "กำลังค้นหาโรงแรม",
    "旅程价格": "ราคาการเดินทาง",
    "佣金": "คอมมิชชั่น",
    "搜索": "ค้นหา",
    "评分": "คะแนน",
    "评论": "ความคิดเห็น",
    "评论-t2": "รีวิว",
    "你还没有设置支付密码，请前往设置": "คุณยังไม่ได้ตั้งรหัสผ่านการชำระเงินโปรดไปที่การตั้งค่า",
    "请输入": "กรุณากรอก",
    "请选择": "โปรดเลือก",
    "账户明细": "รายละเอียดบัญชี",
    "等级": "ระดับ",
    "每日订单": "คำสั่งซื้อรายวัน",
    "设置": "การตั้งค่า",
    "语言设置": "การตั้งค่าภาษา",
    "客服": "บริการลูกค้า",
    "客服2": "บริการ",
    "投诉": "ร้องเรียน",
    "投诉客户服务": "ร้องเรียนบริการลูกค้า",
    "帮助": "ช่วยเหลือ",
    "复制成功": "คัดลอกสำเร็จ",
    "位置、中心": "ตำแหน่ง, ศูนย์กลาง",
    "你好": "สวัสดี",
    "我的账户": "บัญชีของฉัน",
    "头像": "รูปโปรไฟล์",
    "昵称": "ชื่อเล่น",
    "设置昵称": "ตั้งชื่อเล่น",
    "输入酒店": "กรอกโรงแรม",
    "住宿问答": "คำถามและคำตอบเกี่ยวกับที่พัก",
    "如果您想了解更多住宿信息，请查看其他房客的问题": "หากคุณต้องการข้อมูลเพิ่มเติมเกี่ยวกับที่พักโปรดดูคำถามจากผู้เข้าพักคนอื่น",
    "酒店问答_Q1": "ห้อง Double และ Twin ต่างกันอย่างไร?",
    "酒店问答_A1": "ห้อง Double มีเตียงคู่ 1 เตียงและห้อง Twin มีเตียงเดี่ยว 2 เตียง หากห้องเรียกว่า Double/Twin สามารถตั้งค่าได้สำหรับทั้งสองประเภท ข้อมูลนี้มีประโยชน์หรือไม่?",
    "酒店问答_Q2": "ราคารวมอะไรบ้าง?",
    "酒店问答_A2": "คุณสามารถตรวจสอบค่าใช้จ่ายอื่น ๆ เช่นอาหารเช้า ภาษีหรือค่าบริการที่รวมอยู่ ข้อมูลเหล่านี้ยังสามารถหาได้ในอีเมลยืนยันการจองของคุณหลังจากจองแล้ว และคุณยังสามารถดูการจองของคุณในบัญชีของคุณได้",
    "立即预订可享受酒店特别折扣": "จองเดี๋ยวนี้พร้อมส่วนลดโรงแรมพิเศษ",
    "最低": "ต่ำสุด",
    "折扣": "ส่วนลด",
    "订单时间": "เวลาสั่งซื้อ",
    "产品": "ผลิตภัณฑ์",
    "订单数量": "จำนวนการสั่งซื้อ",
    "提交订单": "ส่งคำสั่งซื้อ",
    "可用余额": "ยอดเงินคงเหลือ",
    "冻结金额": "จำนวนเงินที่ถูกตรึง",
    "佣金比例": "อัตราค่าคอมมิชชั่น",
    "获得佣金": "ได้รับค่าคอมมิชชั่น",
    "完成订单": "เสร็จสิ้นการสั่งซื้อ",
    "请至少选择一项": "โปรดเลือกอย่างน้อยหนึ่งรายการ",
    "请先选择星星": "โปรดเลือกดาวก่อน",
    "公告": "ประกาศ",
    "邀请好友": "เชิญเพื่อน",
    "邀请好友赚取佣金": "เชิญเพื่อนและรับค่าคอมมิชชั่น",
    "条款&协议": "ข้อตกลงและข้อกำหนด",
    "注册协议": "ข้อตกลงการลงทะเบียน",
    "我已阅读并同意": "ฉันได้อ่านและยอมรับ",
    "加载中...": "กำลังโหลด...",
    "没有更多数据": "ไม่มีข้อมูลเพิ่มเติม",
    "信息": "ข้อความ",
    "快乐的客户": "ลูกค้าที่มีความสุข",
    "快乐的酒店老板": "เจ้าของโรงแรมแสนสุข",
    "评论1": "แพลตฟอร์มที่น่าทึ่งพร้อมฟีเจอร์ที่ยอดเยี่ยม คุ้มค่าแก่การสำรวจจริงๆ!",
    "评论2": "การบริการลูกค้าเชิงรุกที่จะแนะนำคุณหากคุณไม่ชัดเจนเกี่ยวกับแพลตฟอร์ม",
    "评论3": "แพลตฟอร์มที่เชื่อถือได้ คุ้มค่าที่จะลอง!",
    "评论4": "ลงรายชื่อโรงแรมของฉันที่นี่และบรรลุผลทางการตลาดที่ยอดเยี่ยม",
    "客户评价": "รีวิวลูกค้า",
    "查看我们用户的真实评价。": "ดูรีวิวจริงจากผู้ใช้ของเรา",
}